/* For ease all css classes are defined here.
   May be spread out later if the app grows in size
*/

html, .App {
  background-color: var(--fullwhite);
  padding: 0;
  margin: 0;
  font-family: Arial, "Americane", "Neue", Helvetica, sans-serif, "Roboto Slab", "Montserrat";
}

:root {
  --gray: #353541;
  --orange: #F18544;
  --black: #040405d6;
  --lightblack: #7f7f8fd6;
  --white: antiquewhite;
  --fullwhite: #ffff;
}

.blob-title {
  font-size: x-large;
  color: rgb(234, 228, 220);
  font-weight: bolder;
}

.convo-section {
  margin: 5rem 0 0 0;
  width: 80vw;
  height: calc(100vh - 15rem); 
  overflow: scroll;
}

.convo-bubble {
  width: 70%;
  border-radius: 20px;
  min-height: 8vh;
}

.convo-content {
  margin: 1%;
}

.customCard {
  height: 60vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  background-color: #e08537;
}

.cardIcon {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.cardInfo {
  margin: auto;
  text-align: center;
}

.customCard:hover {
  cursor: pointer;
  background-color: rgb(255, 177, 87);
}

.file-upload-info {
  background-color: var(--black);
  color: var(--white);
}



.interview-bubble {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  border-bottom-right-radius: 0;
}

.JDTextArea {
  width: 50%;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 2%;
  margin-left: 17%;
  border-style: inherit;
}

.main {
  flex: 1;
  box-sizing: border-box;
  margin-top: 0px;
  margin-left: 0px;
}

.notes-text {
  background-color: rgb(225, 176, 107);
  min-height: 100px;
}

.noResultsMessage {
  width: 75vw;
  margin-top: 8vh;
  text-align: center;
  position: absolute;
}

.navbar {
  background-color: #E08537;
}

.query-input {
  height: 60px;
  position: fixed;
  bottom: 5vh;
  width: 80vw;
}

.query-text {
  background-color: var(--orange);
}

.response-text {
  background-color: var(--white);
}

.searchbar {
  background-color:  #e49d60;
}

.separator {
  border-top: 3px dashed var(--white);
}

.side {
  box-sizing: border-box;
  flex: inherit;
  overflow-x: hidden
}

.signInBtn {
  background-color: #F18544;
  padding: 30px;
  font-size: xx-large;
  border-radius: 5px;
  border: #F18544;
  color: white;
  
  
}

.signInFlex {
  height: 100vh;
}

.uploadBtn {
  background-color: #c9c0c0;
  border-block-color: #c9c0c0;
  border:#c9c0c0;
  border-style: solid;
  color:white;
  width: 115%;
  height: 38%
  
}
.newResponse{
  border-radius: 0px;
  background-color: white;
  width: 97%;
  overflow: hidden;
  border-style: unset;
  margin-left: 3%;
  font-family: "Roboto Slab";
  color:#887E7E 
}


.blackBoxes{ /*Courtesy of G*/
  border-radius: 10px;
  background-color: black; 
  width: 20% !important;
  margin-right: 3%;
  font-size:30px;
  text-align:center;
  height:71%;
}

.sampleUser{
  color:white;
  border-radius: 6px;
  background-color: #4CABAB;
  font-size:30px;
  height:15%;
  font-size: 12px;
  margin-left: 10%;
  border:#4CABAB;
  font-weight: bold;
}

.candidateSettings{
  margin-left: 10%;
  font-size: 12px;
  color:black;
  margin-bottom: 5%;
}
.color {
  --bs-table-striped-bg: red !important;
}




/*Overriden Classes*/

.dropdown-toggle:after{
  margin-left: 100% !important;
  position: fixed;
}
#dash .css-dip3t8 {
 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #E08537 ;
  height: 100%;
}

.css-dip3t8 {
 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #ffffff ;
  height: 100%;
}

.ps-sidebar-container {
  background-color: white;
}

.page-link{
  color: #E08537 !important ;
}
.active>.page-link, .page-link.active {
  background-color: #E08537 !important ;
  color: white !important;
  border: #E08537 !important;
}


td {
  max-width: 300px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

 #choosePresetModal.modal-dialog{
  max-width: 750px !important;
}

#search::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: white !important;
}
#search:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color: white !important;
 opacity:  1;
}
#search::-moz-placeholder { /* Mozilla Firefox 19+ */
 color: white !important;
 opacity:  1;
}
#search:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:white !important;
}
#search::-ms-input-placeholder { /* Microsoft Edge */
 color:white !important;
}

#search::placeholder { /* Most modern browsers support this now. */
 color:white !important;
}